<template>
  <b-card>
    <site-form/>  
  </b-card>
  
</template>

<script>
import SiteForm from "@/components/SiteForm";

export default {
  name: "SiteAdd",
  components: {SiteForm}
}
</script>

<style scoped>

</style>